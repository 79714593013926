import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const FourKeysOutOfDebtPage = () => {
    const article = {
        id: '6f286222-2fc8-52bc-8d6e-757977c59649',
        title: '4 Keys to Getting Out of Debt',
        slug: '/4-keys-out-of-debt/',
        date: '2018-01-21T23:24:53.000Z',
        modified: '2021-11-08T17:16:49.000Z',
        excerpt: 'With so many resources out there offering advice on how to get out of debt, it all comes down to what works best for you. Here we&#8217;ve broken down some major keys to know, so you can be on your way to overcoming your debt.',
        featured_image: {
            source_url: '/media/keys-to-get-out-of-debt.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 9,
        content: (
            <>
                <p>You probably have debt.</p>

                <p>
                    In America, debt is a way of life. People borrow money to buy houses, pay for college, buy cars, and buy the things they need in everyday
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=4-keys-out-of-debt&amp;sub2=life" rel="noopener noreferrer" target="_blank">life</a>
                    {' '}
                    or to fill the gaps in their monthly budgets or when between jobs.
                </p>

                <p>According to&nbsp;recent data, the average U.S. household has around $137,063 in debt.</p>

                <p>While some debt is necessary and beneficial (&quot;good debt&quot; such as mortgages that help us afford a nice quality of life in a house that grows in value, or college loans that help improve our lifelong income prospects), many Americans are trying hard to get out of debt and live more simply with greater financial freedom.</p>

                <p>Unfortunately, that doesn&rsquo;t always work.</p>

                <p>
                    <a href="http://www.uscourts.gov/statistics-reports/bapcpa-report-2016">According to US Courts</a>
                    , nearly 750,000 people filed for bankruptcy in 2016.
                </p>

                <p>Many people see bankruptcy as a clean slate, a way to start anew without their previous debt hanging over their heads.</p>

                <p>The problem is that bankruptcy stays on your credit report for up to 10 years and can greatly hinder your quality of life by preventing you from getting a mortgage, auto loan, or other type of loan. </p>

                <LazyLoadImage src="/media/personal-bankruptcy-filings-2017-1.jpg" alt="Personal Bankruptcy Filings (2017)" />

                <h3>Understand your debt and then you can overcome it.</h3>

                <h2 id="looking-behind-the-debt">Looking behind the debt</h2>

                <p>
                    Rather than seeing debt as the issue to be resolved, it makes sense to look at the&nbsp;&quot;why&quot;&nbsp;behind the debt. You&rsquo;re not alone. Many people (even
                    {' '}
                    <a href="https://www.debtconsolidation.com/tyler-perry/">celebrities such as Tyler Perry</a>
                    ) have had to meticulously analyze the &quot;why&quot; behind their financial woes. Debt is often the result of other financial difficulties, including:
                </p>

                <p>
                    <strong>Overspending:</strong>
                    {' '}
                    Consumers who live beyond their means and don&rsquo;t keep their spending under control are more likely to go into debt.
                </p>

                <p>The debt is the consequence of the overspending. </p>

                <p>Educational tools and the help of a counselor can help people make and stick to a budget.</p>

                <p>
                    <strong>Medical bills:</strong>
                    {' '}
                    According to&nbsp;The Fragile Middle Class: Americans in Debt&nbsp;by Elizabeth Warren (back in her Harvard Law School days), 91% of those who file bankruptcy do so after losing a job, experiencing a major medical event, or a divorce.
                </p>
                <p>Debt in these cases is a side effect of major financial disasters.</p>

                <h3>Prepare for debt and save for a rainy day</h3>

                <p>In either case, two of the ways to guard against debt and bankruptcy are to prepare for financial emergencies by building an emergency fund and to keep spending within the limits of income. </p>

                <p>While savings is must-do for changing spending habits, major financial catastrophes cannot be scheduled, but they can lessen the blow.</p>

                <p>Rather than being the problem, debt is often an indication that something else is an issue, whether that something else is a failure to live by a budget or to prepare for a rainy day. </p>

                <p>If debt is a recurring issue, rather than constantly looking to get rid of the debt, it might make sense to instead look at what has been leading up to the debt. </p>

                <p>By tackling these other problems, and focusing on improving general financial habits, the debt issue has the potential to solve itself.</p>

                <h2 id="4-keys-to-getting-out-of-debt">4 keys to getting out of debt</h2>

                <p>Mary DeGrado Evanson is a university administrator who lives in the Midwestern U.S. </p>

                <p>
                    In 2000, she and her then-boyfriend, now husband, graduated from college, and they each had about $25,000 in
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=4-keys-out-of-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    , $10,000 in
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=4-keys-out-of-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    debt and also carried small balances on several department store cards.
                </p>

                <p>
                    It&rsquo;s not unusual for young people to have some
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=4-keys-out-of-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    and credit card debt when they are just getting started in life – after all, average student loan debt in the U.S. is
                    {' '}
                    <a href="http://www.finaid.org/loans/">approximately $27,000 per student</a>
                    , and a recent study from Fidelity found that 2013 college graduates had an
                    {' '}
                    <a href="http://www.fidelity.com/inside-fidelity/individual-investing/college-grads-surprised-by-student-debt-level-exceeds-35000">average debt burden of $35,000</a>
&nbsp;(including credit card debt).
                </p>

                <p>But a few years later, Mary realized that they needed to make some serious changes.</p>

                <h3>1. Pay off your highest interest credit cards first</h3>

                <p>
                    Mary and her husband sat down with their various
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=4-keys-out-of-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    statements and figured out which cards and loans had the highest interest rates, and then made a priority to pay off the highest-interest cards first.
                </p>

                <p>&quot;We checked each card off the list a few months at a time,&quot; said Mary. &quot;You get a high when you shop, but nothing compares to the feeling of freedom and power you get when you pay off a credit card and cut it up!&quot;</p>

                <h3>2. Use debt consolidation for student loans</h3>

                <p>Depending on which student loans you have and who is the issuer/owner of your student loan, there might be programs available to consolidate your multiple student loans into a single monthly payment. </p>

                <p>
                    &quot;We consolidated our
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=4-keys-out-of-debt&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    at a low interest rate so that our monthly payment was lower, but then we continued making the same minimum payments we&rsquo;d been making at our prior interest rates, and we paid more when we could,&quot; said Mary.
                </p>

                <p>&quot;Eventually, our student loans got to be at a very manageable level and one at a time, over several years, we wrote checks to pay them off completely.&quot;</p>

                <h3>3. Drive a cheaper car</h3>

                <p>Owning a car is expensive, and Mary and her husband used to own a big SUV that got very bad gas mileage. </p>

                <p>As the price of gas has gone up in recent years, it cost them too much money to keep driving that car. </p>

                <p>
                    According to
                    {' '}
                    <a href="http://www.consumerreports.org/cro/2012/12/what-that-car-really-costs-to-own/index.htm">Consumer Reports</a>
                    , the median cost of car ownership is $9,100 per year (including gas, maintenance and depreciation) during the first five years of owning it – and big SUVs are even more expensive, with a median cost of ownership of $13,000 per year.
                </p>

                <p>

                    <LazyLoadImage src="/media/cost-of-owning-and-operating-a-new-vehicle-2017-2.jpg" alt="Cost of Owning and Operating a New Vehicle (2017)" />
                </p>

                <p>&quot;We got rid of the giant gas-guzzling SUV and bought a small car that we shared until we were able to buy another car with cash,&quot; said Mary. </p>

                <p>It might seem like a hardship to go from being a two-car family to a one-car family, but freeing up that extra money in your budget gives you extra cash to put towards paying off your debts.</p>

                <h3>4. Enjoy a simpler life</h3>

                <p>Mary and her husband used to live in Chicago, but big city living can be expensive. </p>

                <p>Mary and her husband made a variety of little changes in their lives that helped them save big money.</p>

                <p>&quot;We stopped going out to bars and restaurants in Chicago every weekend and stayed home with friends more often,&quot; said Mary. </p>

                <p>&quot;We took cabs less, and walked more. We rented movies instead of going to the theater twice a week. I gave myself a grocery budget and started meal planning, so we were much more purposeful and focused on how much we spent on food. One of the biggest everyday changes was when we started taking our lunches to work. Before, we would each go out to lunch during the workday and spend at least $8 per day on lunch. That adds up to $80 per week, $320 month, $16,640 a year in savings!&quot;</p>

                <p>
                    Mary made sure that she and her husband truly saved money each week by keeping track of their spending and constantly moving money into
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=4-keys-out-of-debt&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    as they went along.

                </p>

                <p>&quot;A big part of our debt reduction plan required us to actively move money into savings throughout the month,&quot; she said.</p>

                <p>
                    &quot;For example, if we saved $80 per week by not going out to lunch, we immediately put that $80 into our
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=4-keys-out-of-debt&amp;sub2=savings-account" rel="noopener noreferrer" target="_blank">savings account</a>
                    . This helped us make sure we were actually saving that money, which otherwise could easily have disappeared with a quick trip to the mall.&quot;
                </p>

                <h2 id="2-ways-you-can-attack-debt-without-help">2 ways you can attack debt without help</h2>

                <p>If Mary and her husband&rsquo;s methods don&rsquo;t appeal to you, there are other ways you can reduce your overall debt. </p>

                <p>Personal finance gurus Dave Ramsey and Suze Orman have competing ways of attacking credit card debt, but both are variations of the &quot;snowball&quot; plan in which you pay off one debt in full then take the money you were paying on that bill to make larger payments on your next debt until they can all be stamped &quot;paid-in-full.&quot; </p>

                <p>The concept is that if you concentrate on one debt your efforts to pay it off will be more worthwhile than trying to pay off small amounts of debt on various credit cards.</p>

                <p>Both plans require you to create a budget and develop the discipline to stick to your plan. You need to immediately stop using credit to pay for anything.</p>

                <p>If you&rsquo;re ready for the challenge of creating your own debt repayment plan, consider these two options:</p>

                <h3>1. Pay off the lowest balance first</h3>

                <p>
                    <a href="http://www.daveramsey.com/article/get-out-of-debt-with-the-debt-snowball-plan/">Dave Ramsey&rsquo;s snowball plan</a>
                    {' '}
                    recommends that you pay only the minimum payment on all of your credit card debt except for one.
                </p>

                <p>He suggests that you list all your debts in order starting with the smallest balance first. In other words, like this:</p>

                <ul className="list">

                    <li>$2,200 to Capital One / minimum payment: $61 / interest rate: 21%</li>

                    <li>$4,400 to Discover / minimum payment: $99 / interest rate: 15%</li>

                    <li>$5,600 to Bank of America / minimum payment: $159 / interest rate: 22%</li>

                </ul>

                <p>Then, you tackle the smallest balance first.</p>

                <p>If you have $700 in your budget to devote to credit card debt, then you should pay the minimum on the larger balance cards and $442 each month on the Capital One card. </p>

                <p>
                    It will take six months for that card to be paid in full according to a
                    {' '}
                    <a href="http://www.bankrate.com/calculators/credit-cards/credit-card-payoff-calculator.aspx">credit card calculator</a>
                    .
                </p>

                <p>At that time, your Discover Card balance will be $4,101. </p>

                <p>You&rsquo;ll have $541 per month to pay on that balance, which you can pay off in nine months. </p>

                <p>Once that credit card debt is repaid, you can then devote $700 per month to eliminate that Bank of America debt, which will be $4,816 after 15 months of minimum payments. </p>

                <p>

                    <LazyLoadImage src="/media/average-credit-card-debt-in-america-2017-3.jpg" alt="Average Credit Card Debt in America (2017)" />
                </p>

                <p>
                    It will take you 8 months to repay that balance, meaning you will be debt free in 23 months and you will have paid a
                    {' '}
                    <a href="http://www.bankrate.com/calculators/managing-debt/minimum-payment-calculator.aspx">total of $2,744 in interest</a>
                    {' '}
                    during that time.
                </p>

                <p>Ramsey&rsquo;s concept is based on the fact that personal finance is &quot;80% behavior.&quot; </p>

                <p>He believes the psychological boost of paying off one bill in full will boost your enthusiasm for becoming debt free.</p>

                <h3>2. Pay off the highest interest card first</h3>

                <p>
                    <a href="http://apps.suzeorman.com/igsbase/igstemplate.cfm?SRC=MD012&amp;SRCN=aoedetails&amp;GnavID=84&amp;SnavID=20&amp;TnavID&amp;AreasofExpertiseID=5">Suze Orman&rsquo;s debt plan</a>
                    , while similar to Ramsey&rsquo;s in that you tackle one debt at a time, recommends beginning by paying off the debt with the highest interest rate.
                </p>

                <p>This will reduce the amount of interest you&rsquo;ll pay since the debt with the highest rate will be paid off first.</p>

                <p>In the above case, the debt would be ranked like this:</p>

                <ul className="list">

                    <li>$5,600 to Bank of America / minimum payment: $159/ interest rate: 22%</li>

                    <li>$2,200 to Capital One / minimum payment: $61 / interest rate: 21%</li>

                    <li>$4,400 to Discover / minimum payment: $99 / interest rate: 15%</li>

                </ul>

                <p>You would start by paying $540 per month to Bank of America. </p>

                <p>It would take you 12 months to pay off that credit card balance, at which point you can tackle the remaining balance on your Capital One card of $1,950. </p>

                <p>You can pay $601 per month on that credit card and pay off the balance in four months. </p>

                <p>Your remaining balance on your Discover card after 16 months of minimum payments will be $3,746. </p>

                <p>You can pay that off in six months by paying $700 per month and will be debt free in a total of 22 months.</p>

                <h2 id="you-can-get-out-of-debt">You can get out of debt</h2>

                <p>When faced with a deluge of past-due bills, it may seem like you&rsquo;ll never pay them all off.</p>

                <p>That&rsquo;s the stress talking.</p>

                <p> The truth is that anyone can get out of debt—all you need to do is start by examining why you found yourself indebted in the first place, then build a plan of attack.</p>

                <p>Maybe you employ Mary and her husband&rsquo;s tricks to get out, or you apply Dave Ramsey&rsquo;s and Suze Orman&rsquo;s plans. </p>

                <p>The only secret to getting out of debt is this: determination and discipline to stick to a budget.</p>

                <p> Pay more than the minimum balance and whittle away at the principal bit by bit and you&rsquo;ll be debt-free in time. </p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default FourKeysOutOfDebtPage;
